<template>
    <div>
        ใบเสร็จ ข้างในมี
        <br>
        <br>
        ทั้งหมด<br>
ยังไม่ได้พิมพ์<br>
พิมพ์แล้ว<br>
รอตรวจสอบ<br>
ดำเนินการสำเร็จ<br>
<br>
รายละเอียด <br>
https://portal.plm-cargo.com/admin/finance/receipt
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>